export default {
  state: {
    merchants: [],
    paymentModel: '',
  },

  mutations: {
    SET_MERCHANTS(state, merchants) {
      state.merchants = merchants;
    },

    SET_PAYMENT_MODEL(state, paymentModel) {
      state.paymentModel = paymentModel;
    }
  },

  actions: {
    async addMerchant(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/merchants/store', formData);
    },

    async changePaymentModel({ commit }, paymentModel) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/merchants/set-payment-model', {
        model: paymentModel
      })
        .then(() => {
          commit('SET_PAYMENT_MODEL', paymentModel);
        });
    },

    async editMerchant(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/merchants/update', formData);
    },

    async getMerchants({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/merchants')
        .then(response => {
          commit('SET_MERCHANTS', response.data);
        });
    },

    async getPaymentModel({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/merchants/payment-model')
        .then(response => {
          commit('SET_PAYMENT_MODEL', response.data);
        });
    },

    async removeMerchant(_, merchantId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/merchants/delete', {
        id: merchantId
      });
    },

    async syncMerchant(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/organizations/sync-merchants', formData);
    },
  },

  getters: {},

  namespaced: true,
};
