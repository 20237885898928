export default {
  state: {
    history: [],
    landings: [],
    sortParams: {
      page: 1,
      phrase: '',
    },
  },

  mutations: {
    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_HISTORY(state, history) {
      state.history = history?.sort((a, b) => a.year - b.year) || [];
    },

    SET_LANDINGS(state, landings) {
      state.landings = landings;
    },

    SET_SEARCH_PHRASE_PARAM(state, phrase) {
      state.sortParams.phrase = phrase;
    },
  },

  actions: {
    async addLanding(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/landings/store', formData);
    },

    async editLanding(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/landings/update', formData);
    },

    async getHistory({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/company-history')
        .then((response) => {
          commit('SET_HISTORY', response.data);
        });
    },

    async getLandings({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_SEARCH_PHRASE_PARAM', '');
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.searchPhrase !== undefined) {
          commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
        }
      }

      let url = '/panel/landings';

      for (let key in state.sortParams) {
        if (state.sortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
        }
      }

      // eslint-disable-next-line no-undef
      await axios.get(url)
        .then((response) => {
          commit('SET_LANDINGS', response.data);
        });
    },

    async removeLanding(_, landingId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/landings/delete', {
        id: landingId
      });
    },

    async saveHistory(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/company-history/store', formData);
    }
  },

  getters: {},

  namespaced: true,
};
