<template>
  <div class="layout-empty vh-100">
    <ListToasts />

    <div class="layout-empty__content d-flex flex-column h-100">
      <router-view class="flex-grow-1" />

      <div class="layout-empty__footer text-right px-5 py-4 border-top">
        {{ new Date().getFullYear() }} г,
        <b-link href="https://appfox.ru" target="_blank"> Appfox</b-link>, все
        права защищены
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutEmpty',

  components: {
    ListToasts: () => import('@/components/List/Toasts')
  }
};
</script>

<style lang="scss" scoped></style>