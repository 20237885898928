<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import LayoutEmpty from '@/views/Layout/Empty';
import LayoutMain from '@/views/Layout/Main';
import { mapActions, mapMutations } from 'vuex';

export default {
  components: {
    LayoutEmpty,
    LayoutMain
  },

  computed: {
    layout() {
      return `layout-${this.$route.meta.layout || 'empty'}`;
    }
  },

  async created() {
    this.axios.interceptors.response.use(
      undefined,
      (error) =>
        new Promise(() => {
          if (error?.response?.status === 401) {
            this.logout();
            this.$router.go();
          }

          throw error;
        })
    );

    if (this.$cookies.get('niyamaAdminAuthToken')) {
      try {
        this.setUserToken(this.$cookies.get('niyamaAdminAuthToken'));
        await this.getInfo();
      } catch (error) {
        console.error(error);

        this.$toasts.add({
          message: error,
          variant: 'danger'
        });
      }

    }
  },

  methods: {
    ...mapActions('auth', ['getInfo']),
    ...mapMutations({
      logout: 'auth/LOGOUT',
      setUserToken: 'auth/SET_USER_TOKEN',
    })
  }
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
