export default {
  state: {
    servicePushes: [],
  },

  mutations: {
    SET_PUSHES(state, pushes) {
      state.servicePushes = pushes;
    }
  },

  actions: {
    async editServicePush(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/service-push/update', formData);
    },

    async exportPushes(_, params) {
      try {
        let url = '/panel/service-push/?export';

        for (let key in params) {
          if (params[key]) {
            url += `&${key}=${params[key]}`;
          }
        }

        // eslint-disable-next-line no-undef
        const response = await axios.get(url);

        return response.data.url;
      } catch (error) {
        throw error;
      }
    },

    async getServicePushes({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/service-push')
        .then((response) => {
          commit('SET_PUSHES', response.data);
        });
    },
  },

  getters: {},

  namespaced: true,
};

