export default {
  state: {
    orders: [],
    selectedOrder: null,
    sortParams: {
      date_from: '',
      date_to: '',
      id: '',
      operator: false,
      page: 1,
      search: '',
      sort: 'created_at',
      'sort-direction': 'desc',
      'statuses[]': [],
    },
    statuses: {
      'wait_confirm': {
        name: 'Ожидает подтверждения',
        id: -3
      },
      'trash': {
        name: 'trash',
        id: -2
      },
      'draft': {
        name: 'Черновик',
        id: -1
      },
      'new': {
        name: 'Новый',
        id: 0
      },
      'not_confirmed': {
        name: 'Не подтвержден',
        id: 1
      },
      'in_progress': {
        name: 'Готовится',
        id: 2
      },
      'ready': {
        name: 'Готов',
        id: 3
      },
      'awaiting_delivery': {
        name: 'Ожидает доставки',
        id: 4
      },
      'on_the_way': {
        name: 'В пути',
        id: 5
      },
      'delivered': {
        name: 'Доставлен',
        id: 6
      },
      'closed': {
        name: 'Закрыт',
        id: 7
      },
      'canceled': {
        name: 'Отменен',
        id: 8
      },
      'error': {
        name: 'Ошибка',
        id: 10
      },
    }
  },

  mutations: {
    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_DATE_FROM_PARAM(state, date) {
      state.sortParams.date_from = date;
    },

    SET_DATE_TO_PARAM(state, date) {
      state.sortParams.date_to = date;
    },

    SET_ID_PARAM(state, orderId) {
      state.sortParams.id = orderId;
    },

    SET_OPERATOR_PARAM(state, value) {
      state.sortParams.operator = value;
    },

    SET_ORDERS(state, orders) {
      state.orders = orders;
    },

    SET_SEARCH_PHRASE_PARAM(state, phrase) {
      state.sortParams.search = phrase;
    },

    SET_SELECTED_ORDER(state, order) {
      state.selectedOrder = order;
    },

    SET_SORT_COLUMN_PARAM(state, sortColumn) {
      state.sortParams.sort = sortColumn;
    },

    SET_SORT_DIRECTION_PARAM(state, sortDirection) {
      state.sortParams['sort-direction'] = sortDirection;
    },

    SET_STATUSES_PARAM(state, statuses) {
      state.sortParams['statuses[]'] = statuses;
    },
  },

  actions: {
    async exportOrders({ state }, isBackgroundExport = false) {
      let url = '/panel/orders';
      url += isBackgroundExport ? '?export_background' : '?export';

      for (let key in state.sortParams) {
        const value = state.sortParams[key];

        if (value) {
          if (value instanceof Array) {
            if (value.length) {
              value.forEach((item) => {
                url += `&${key}=${item}`;
              });
            }
          } else {
            url += `&${key}=${value}`;
          }
        }
      }

      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(url);

        return response.data.url;
      } catch (error) {
        throw error;
      }
    },

    async getOrderById({ commit }, orderId) {
      // eslint-disable-next-line no-undef
      await axios.get(`/panel/orders/${orderId}`)
        .then((response) => {
          commit('SET_SELECTED_ORDER', response.data);
        });
    },

    async getOrders({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_DATE_FROM_PARAM', '');
        commit('SET_DATE_TO_PARAM', '');
        commit('SET_ID_PARAM', '');
        commit('SET_OPERATOR_PARAM', false);
        commit('SET_SEARCH_PHRASE_PARAM', '');
        commit('SET_SORT_COLUMN_PARAM', 'created_at');
        commit('SET_SORT_DIRECTION_PARAM', 'desc');
        commit('SET_STATUSES_PARAM', []);
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.dateFrom !== undefined) {
          commit('SET_DATE_FROM_PARAM', payload.dateFrom);
        }

        if (payload.dateTo !== undefined) {
          commit('SET_DATE_TO_PARAM', payload.dateTo);
        }

        if (payload.orderId !== undefined) {
          commit('SET_ID_PARAM', payload.orderId);
        }

        if (payload.operator !== undefined) {
          commit('SET_OPERATOR_PARAM', payload.operator);
        }

        if (payload.searchPhrase !== undefined) {
          commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
        }

        if (payload.sortColumn !== undefined) {
          commit('SET_SORT_COLUMN_PARAM', payload.sortColumn);
        }

        if (payload.sortDirection !== undefined) {
          commit('SET_SORT_DIRECTION_PARAM', payload.sortDirection);
        }

        if (payload.statuses !== undefined) {
          commit('SET_STATUSES_PARAM', payload.statuses);
        }
      }

      let url = '/panel/orders';

      for (let key in state.sortParams) {
        const value = state.sortParams[key];

        if (value) {
          if (value instanceof Array) {
            if (value.length) {
              value.forEach((item) => {
                url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${item}`;
              });
            }
          } else {
            url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${value}`;
          }
        }
      }

      // eslint-disable-next-line no-undef
      await axios.get(url)
        .then((response) => {
          commit('SET_ORDERS', response.data);
        });
    },

    async makeRefund(_, orderId) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/orders/${orderId}/refund`);
    },

    async removeOrder(_, orderId) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/orders/${orderId}/delete`);
    },

    async sendToIIKO(_, orderId) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/orders/${orderId}/sendIiko`);
    }
  },

  getters: {},

  namespaced: true,
};
