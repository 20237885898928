import Vue from 'vue';
import store from '@/store';

Vue.use({
  install(Vue) {
    Vue.mixin({
      methods: {
        isHaveRights(path) {
          return store.getters.checkRoleAccess[path];
        }
      }
    });
  }
});