export default {
  state: {
    promocodes: [],
    sortParams: {
      page: 1,
      per_page: 20,
      search: '',
    },
  },

  mutations: {
    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_PER_PAGE_PARAM(state, perPage) {
      state.sortParams.per_page = perPage;
    },

    SET_PROMOCODES(state, promocodes) {
      state.promocodes = promocodes;
    },

    SET_SEARCH_PHRASE_PARAM(state, phrase) {
      state.sortParams.search = phrase;
    },
  },

  actions: {
    async addPromocode(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/promocodes/store', formData);
    },

    async editPromocode(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/promocodes/update', formData);
    },

    async getPromocodes({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_PER_PAGE_PARAM', 20);
        commit('SET_SEARCH_PHRASE_PARAM', '');
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.perPage !== undefined) {
          commit('SET_PER_PAGE_PARAM', payload.perPage);
        }

        if (typeof payload.searchPhrase === 'string') {
          commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
        }
      }

      let url = '/panel/promocodes';

      for (let key in state.sortParams) {
        if (state.sortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
        }
      }

      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(url);

        if (payload?.returnData) {
          return response.data;
        } else {
          commit('SET_PROMOCODES', response.data);
        }
      } catch (error) {
        throw error;
      }
    },

    async importPromocodes(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/promocodes/import', formData);
    },

    async removePromocode(_, promocodeId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/promocodes/delete', {
        id: promocodeId
      });
    },

    async removeSeveralPromocodes(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/promocodes/delete-pack', formData);
    }
  },

  getters: {},

  namespaced: true,
};
