export default {
  state: {
    expendables: [],
  },

  mutations: {
    SET_EXPENDABLES(state, expendables) {
      state.expendables = expendables;
    },
  },

  actions: {
    async addExpendable(_, payload) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/nomenclature/expendable/createproduct', payload);
    },

    async editExpendable(_, payload) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/nomenclature/expendable/create', payload);
    },

    async getExpendableById(_, expendableId) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(`/panel/nomenclature/expendable/${expendableId}`);

        return response.data.data;
      } catch (error) {
        throw error;
      }
    },

    async getExpendables({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/nomenclature/expendable')
        .then(response => {
          commit('SET_EXPENDABLES', response.data.data);
        });
    },

    async removeExpendable(_, expendableId) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/nomenclature/expendable/${expendableId}/delete`, {
        product_id: expendableId,
        expendable: expendableId
      });
    }
  },

  getters: {},

  namespaced: true,
};
