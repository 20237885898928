export default {
  state: {
    bonuses: [],
  },

  mutations: {
    SET_BONUSES(state, bonuses) {
      state.bonuses = bonuses;
    },
  },

  actions: {
    async editBonus(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/bonus/update', formData);
    },

    async getBonuses({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/bonus/')
        .then(response => {
          commit('SET_BONUSES', response.data);
        });
    },

    async importBonuses() {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/bonus/import');
    },

    async removeBonus(_, bonusId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/bonus/import', {
        id: bonusId
      });
    }
  },

  getters: {},

  namespaced: true,
};
