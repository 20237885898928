export default {
  state: {
    blacklist: [],
    sortParams: {
      page: 1,
      phrase: '',
    },
  },

  mutations: {
    SET_BLACKLIST(state, blacklist) {
      state.blacklist = blacklist;
    },

    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_SEARCH_PHRASE_PARAM(state, phrase) {
      state.sortParams.phrase = phrase;
    },
  },

  actions: {
    async addToBlacklist(_, phone) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/blacklist/add', {
        phone
      });
    },

    async getBlacklist({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_SEARCH_PHRASE_PARAM', '');
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.searchPhrase !== undefined) {
          commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
        }
      }

      let url = '/panel/blacklist';

      for (let key in state.sortParams) {
        if (state.sortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
        }
      }

      // eslint-disable-next-line no-undef
      await axios.get(url)
        .then((response) => {
          commit('SET_BLACKLIST', response.data);
        });
    },

    async removeFromBlacklist(_, phone) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/blacklist/delete', {
        phone
      });
    },
  },

  getters: {},

  namespaced: true,
};
