export default {
  state: {
    qa: [],
    sortParams: {
      date_from: null,
      date_to: null,
      page: 1,
      type: ''
    },
  },

  mutations: {
    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_DATE_FROM_PARAM(state, dateFrom) {
      state.sortParams.date_from = dateFrom;
    },

    SET_DATE_TO_PARAM(state, dateTo) {
      state.sortParams.date_to = dateTo;
    },

    SET_QA(state, qa) {
      state.qa = qa;
    },

    SET_TYPE_PARAM(state, type) {
      state.sortParams.type = type;
    },
  },

  actions: {
    async exportQa({ state }) {
      let url = '/panel/qa?export';

      for (let key in state.sortParams) {
        if (key !== 'page' && state.sortParams[key]) {
          url += `&${key}=${state.sortParams[key]}`;
        }
      }

      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(url);

        return response.data.url;
      } catch (error) {
        throw error;
      }
    },

    async getQa({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_DATE_FROM_PARAM', null);
        commit('SET_DATE_TO_PARAM', null);
        commit('SET_TYPE_PARAM', '');
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.dateFrom !== undefined) {
          commit('SET_DATE_FROM_PARAM', payload.dateFrom);
        }

        if (payload.dateTo !== undefined) {
          commit('SET_DATE_TO_PARAM', payload.dateTo);
        }

        if (payload.type !== undefined) {
          commit('SET_TYPE_PARAM', payload.type);
        }
      }

      let url = '/panel/qa';

      for (let key in state.sortParams) {
        if (state.sortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
        }
      }

      // eslint-disable-next-line no-undef
      await axios.get(url)
        .then((response) => {
          commit('SET_QA', response.data);
        });
    },

    async getQaById(_, qaId) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(`/panel/qa/${qaId}`);

        return response.data;
      } catch (error) {
        throw error;
      }
    },

    async removeQa(_, qaId) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/qa/${qaId}/delete`);
    }
  },

  getters: {},

  namespaced: true,
};
