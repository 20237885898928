export default {
  state: {
    versions: [],
    sortParams: {
      page: 1,
    },
  },

  mutations: {
    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_VERSIONS(state, versions) {
      state.versions = versions;
    },
  },

  actions: {
    async addVersion(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/app-versions/create', formData);
    },

    async editVersion(_, { itemId, formData }) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/app-versions/${itemId}/update`, formData);
    },

    async getVersions({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }
      }

      let url = '/panel/app-versions';

      for (let key in state.sortParams) {
        if (state.sortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
        }
      }

      // eslint-disable-next-line no-undef
      await axios.get(url)
        .then((response) => {
          commit('SET_VERSIONS', response.data);
        });
    },

    async removeVersion(_, versionId) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/app-versions/${versionId}/delete`);
    }
  },

  getters: {},

  namespaced: true,
};
