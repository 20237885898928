export default {
  state: {
    organizations: [],
    selectedOrganization: null,
    sortParams: {
      page: 1,
      phrase: '',
    },
  },

  mutations: {
    SET_ORGANIZATIONS(state, organizations) {
      state.organizations = organizations;
    },

    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_SEARCH_PHRASE_PARAM(state, phrase) {
      state.sortParams.phrase = phrase;
    },

    SET_SELECTED_ORGANIZATION(state, organization) {
      state.selectedOrganization = organization;
    }
  },

  actions: {
    async addOrganization(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/organizations/store', formData);
    },

    async editOrganization(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/organizations/update', formData);
    },

    async getOrganizationById({ commit }, { organizationId, needReturnData }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(`/panel/organizations/${organizationId}`);

        if (needReturnData) {
          return response.data;
        } else {
          commit('SET_SELECTED_ORGANIZATION', response.data);
        }
      } catch (error) {
        throw error;
      }
    },

    async getOrganizations({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_SEARCH_PHRASE_PARAM', '');
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.searchPhrase !== undefined) {
          commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
        }
      }

      let url = '/panel/organizations';

      for (let key in state.sortParams) {
        if (state.sortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
        }
      }

      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(url);

        if (payload?.returnData) {
          return response.data;
        } else {
          commit('SET_ORGANIZATIONS', response.data);
        }
      } catch (error) {
        throw error;
      }
    },

    async importOrganizations() {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/organizations/import');
    },
  },

  getters: {},

  namespaced: true,
};
