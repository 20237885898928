export default {
  state: {
    categories: [],
  },

  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
  },

  actions: {
    async addCategory(_, name) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/nomenclature/categories/store', {
        name
      });
    },

    async editCategory(_, { id, name }) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/nomenclature/categories/${id}/update`, {
        name
      });
    },

    async getCategories({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/nomenclature/categories')
        .then(response => {
          commit('SET_CATEGORIES', response.data.data);
        });
    },

    async removeCategory(_, categoryId) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/nomenclature/categories/${categoryId}/delete`);
    }
  },

  getters: {},

  namespaced: true,
};
