export default {
  state: {
    seo: [],
    sortParams: {
      page: 1,
      search: '',
    },
  },

  mutations: {
    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_SEO(state, seo) {
      state.seo = seo;
    },

    SET_SEARCH_PHRASE_PARAM(state, phrase) {
      state.sortParams.search = phrase;
    },
  },

  actions: {
    async editSeo(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/seo-pages/update', formData);
    },

    async getRelations(_, type) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(`/panel/seo-pages/relations?relation_type=${type}`);

        return response.data;
      } catch (error) {
        throw error;
      }
    },

    async getSeo({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_SEARCH_PHRASE_PARAM', '');
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.searchPhrase !== undefined) {
          commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
        }
      }

      let url = '/panel/seo-pages';

      for (let key in state.sortParams) {
        if (state.sortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
        }
      }

      // eslint-disable-next-line no-undef
      await axios.get(url)
        .then((response) => {
          commit('SET_SEO', response.data);
        });
    },
  },

  getters: {},

  namespaced: true,
};
