export default async function roleAccess({
  next,
  store,
  from,
  to
}) {
  if (to.meta.roles && !to.meta.roles.includes(store.state.auth.role)) {
    return next(from);
  } else {
    return next();
  }
}
