export default {
  state: {
    count: 0,
    toasts: [],
  },

  mutations: {
    ADD_TOAST(state, item) {
      state.count++;
      const toast = {
        id: state.count,
        isVisible: true,
        ...item
      };

      state.toasts.unshift(toast);
    },

    REMOVE_TOAST(state, id) {
      state.toasts.find(item => item.id === id).isVisible = false;
      setTimeout(() => {
        state.toasts = state.toasts.filter(el => el.id !== id);
      }, 500);
    }
  },

  actions: {},

  getters: {},

  namespaced: true,
};
