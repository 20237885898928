export default {
  state: {
    pushIcons: [],
  },

  mutations: {
    SET_ICONS(state, icons) {
      state.pushIcons = icons;
    },
  },

  actions: {
    async addIcon(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/push-icons/create', formData);
    },

    async editIcon(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/push-icons/update', formData);
    },

    async getIcons({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/push-icons')
        .then(response => {
          commit('SET_ICONS', response.data);
        });
    },

    async removeIcon(_, iconId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/push-icons/delete', {
        id: iconId
      });
    }
  },

  getters: {},

  namespaced: true,
};
