export default function auth({
  $cookies,
  next,
  store
}) {
  if (!store.state.auth.isLoggedIn && !$cookies.get('niyamaAdminAuthToken')) {
    return next({
      name: 'Авторизация'
    });
  }

  return next();
}
