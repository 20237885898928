export default {
  state: {
    ingredients: [],
  },

  mutations: {
    SET_INGREDIENTS(state, ingredients) {
      state.ingredients = ingredients;
    },
  },

  actions: {
    async addIngredient(_, name) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/nomenclature/ingredients/store', {
        name
      });
    },

    async editIngredient(_, { id, name }) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/nomenclature/ingredients/${id}/update`, {
        name
      });
    },

    async getIngredients({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/nomenclature/ingredients')
        .then(response => {
          commit('SET_INGREDIENTS', response.data.data);
        });
    },

    async removeIngredient(_, ingredientId) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/nomenclature/ingredients/${ingredientId}/delete`);
    }
  },

  getters: {},

  namespaced: true,
};
