import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/assets/scss/main.scss';
import '@/plugins/axios';
import '@/plugins/roleAccess';
import '@/plugins/toasts';
import '@/plugins/v-mask';
import '@/plugins/vue-cookies';
import '@/plugins/vee-validate';

Vue.config.productionTip = false;

import {
  BIcon,
  BIconCaretDownFill,
  BIconCheckLg,
  BIconCheckSquare,
  BIconEyeFill,
  BIconExclamationLg,
  BIconInfoLg,
  BIconLockFill,
  BIconPencilFill,
  BIconSearch,
  BIconSquare,
  BIconTrashFill,
  BIconUnlockFill,
  BIconXLg,
  ButtonPlugin,
  CardPlugin,
  CollapsePlugin,
  DropdownPlugin,
  FormCheckboxPlugin,
  FormDatepickerPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormRadioPlugin,
  FormRatingPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  FormTimepickerPlugin,
  ImagePlugin,
  InputGroupPlugin,
  LinkPlugin,
  ListGroupPlugin,
  ModalPlugin,
  NavPlugin,
  OverlayPlugin,
  PaginationPlugin,
  PopoverPlugin,
  SpinnerPlugin,
  TablePlugin,
  ToastPlugin,
  TooltipPlugin
} from 'bootstrap-vue';

Vue.use(ButtonPlugin);
Vue.use(CardPlugin);
Vue.use(CollapsePlugin);
Vue.use(DropdownPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormFilePlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormRatingPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormTimepickerPlugin);
Vue.use(ImagePlugin);
Vue.use(InputGroupPlugin);
Vue.use(LinkPlugin);
Vue.use(ListGroupPlugin);
Vue.use(ModalPlugin);
Vue.use(NavPlugin);
Vue.use(OverlayPlugin);
Vue.use(PaginationPlugin);
Vue.use(PopoverPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TablePlugin);
Vue.use(ToastPlugin);
Vue.use(TooltipPlugin);

Vue.component('BIcon', BIcon);
Vue.component('BIconCaretDownFill', BIconCaretDownFill);
Vue.component('BIconCheckLg', BIconCheckLg);
Vue.component('BIconCheckSquare', BIconCheckSquare);
Vue.component('BIconEyeFill', BIconEyeFill);
Vue.component('BIconExclamationLg', BIconExclamationLg);
Vue.component('BIconInfoLg', BIconInfoLg);
Vue.component('BIconLockFill', BIconLockFill);
Vue.component('BIconPencilFill', BIconPencilFill);
Vue.component('BIconSearch', BIconSearch);
Vue.component('BIconSquare', BIconSquare);
Vue.component('BIconTrashFill', BIconTrashFill);
Vue.component('BIconUnlockFill', BIconUnlockFill);
Vue.component('BIconXLg', BIconXLg);


import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use(CKEditor);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
