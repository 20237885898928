<template>
  <div class="layout-main vh-100 vw-100">
    <ListToasts />

    <div class="layout-main__row d-flex h-100">
      <Navigation class="layout-main__navigation" />

      <div class="layout-main__content d-flex flex-column flex-grow-1">
        <div
          class="layout-main__top d-flex justify-content-between align-items-center px-3 py-4 border-bottom"
        >
          <h3 class="layout-main__title mb-0">
            {{ $route.name }}
          </h3>

          <div class="layout-main__actions">
            <span>Вы вошли как: {{ login }}</span>
            <b-button class="ml-3" variant="primary" @click="onLogoutClick">
              Выйти
            </b-button>
          </div>
        </div>

        <div class="layout-main__bottom flex-grow-1 px-3 py-4">
          <Transition name="fade" mode="out-in">
            <router-view />
          </Transition>
        </div>

        <div class="layout-main__footer text-right px-3 py-4 border-top">
          {{ new Date().getFullYear() }} г,
          <b-link href="https://appfox.ru" target="_blank"> Appfox</b-link>, все
          права защищены
        </div>

        <ModalUserEdit
          :is-visible="!!selectedUserForModal"
          @hidden="onUserEditModalHidden"
        />

        <ModalOrder
          :is-visible="!!selectedOrderForModal"
          @hidden="onOrderModalHidden"
        />

        <ModalProduct
          :is-visible="!!selectedProductForModal"
          @hidden="onProductModalHidden"
        />

        <ModalOrganizationEdit
          :is-visible="!!selectedOrganizationForModal"
          :selected-organization="selectedOrganizationForModal"
          @hidden="onOrganizationModalHidden"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'LayoutMain',

  components: {
    ListToasts: () => import('@/components/List/Toasts'),
    Navigation: () => import('@/components/Navigation'),
    ModalOrder: () => import('@/components/Modal/Order'),
    ModalOrganizationEdit: () => import('@/components/Modal/OrganizationEdit'),
    ModalProduct: () => import('@/components/Modal/Product'),
    ModalUserEdit: () => import('@/components/Modal/UserEdit'),
  },

  computed: {
    ...mapState('auth', ['login']),
    ...mapState('orders', ['selectedOrder']),
    ...mapState('organizations', ['selectedOrganization']),
    ...mapState('products', ['selectedProduct']),
    ...mapState('users', ['selectedUser']),

    selectedOrderForModal: {
      get: function () {
        return this.selectedOrder;
      },
      set: function (value) {
        this.setSelectedOrder(value);
      }
    },

    selectedOrganizationForModal: {
      get: function () {
        return this.selectedOrganization;
      },
      set: function (value) {
        this.setSelectedOrganization(value);
      }
    },

    selectedProductForModal: {
      get: function () {
        return this.selectedProduct;
      },
      set: function (value) {
        this.setSelectedProduct(value);
      }
    },

    selectedUserForModal: {
      get: function () {
        return this.selectedUser;
      },
      set: function (value) {
        this.setSelectedUser(value);
      }
    }
  },

  created() {
    this.$root.$on('modal:organization-edit:close', () => {
      this.selectedOrganizationForModal = null;
    });
  },

  methods: {
    ...mapMutations({
      logout: 'auth/LOGOUT',
      setSelectedOrder: 'orders/SET_SELECTED_ORDER',
      setSelectedOrganization: 'organizations/SET_SELECTED_ORGANIZATION',
      setSelectedProduct: 'products/SET_SELECTED_PRODUCT',
      setSelectedUser: 'users/SET_SELECTED_USER',
    }),

    onLogoutClick() {
      this.logout();
      this.$router.go();
    },

    onOrderModalHidden() {
      this.selectedOrderForModal = null;
    },

    onOrganizationModalHidden() {
      this.selectedOrganizationForModal = null;
    },

    onProductModalHidden() {
      this.selectedProductForModal = null;
    },

    onUserEditModalHidden() {
      this.selectedUserForModal = null;
      this.$root.$emit('modal:user-edit:hidden');
    },
  }
};
</script>

<style lang="scss" scoped>
.layout-main {
  &__navigation {
    flex: 0 0 300px;
  }

  &__content {
    max-width: calc(100% - 300px);
  }

  &__bottom {
    max-height: calc(100% - 160px);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>