export default {
  state: {
    recommendations: [],
    sortParams: {
      page: 1,
    },
  },

  mutations: {
    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_RECOMMENDATIONS(state, recommendations) {
      state.recommendations = recommendations;
    },
  },

  actions: {
    async addRecommendation(_, payload) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/recommendations/create', payload);
    },

    async editRecommendation(_, payload) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/recommendations/update', payload);
    },

    async getRecommendations({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }
      }

      let url = '/panel/recommendations';

      for (let key in state.sortParams) {
        if (state.sortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
        }
      }

      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(url);

        if (payload?.returnData) {
          return response.data;
        } else {
          commit('SET_RECOMMENDATIONS', response.data);
        }
      } catch (error) {
        throw error;
      }
    },

    async removeRecommendation(_, itemId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/recommendations/delete', {
        id: itemId
      });
    },
  },

  getters: {},

  namespaced: true,
};
