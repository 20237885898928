export default {
  state: {
    labels: [],
  },

  mutations: {
    SET_LABELS(state, labels) {
      state.labels = labels;
    },
  },

  actions: {
    async addLabel(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/nomenclature/labels/store', formData);
    },

    async editLabel(_, { id, formData }) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/nomenclature/labels/${id}/update`, formData);
    },

    async getLabels({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/nomenclature/labels')
        .then(response => {
          commit('SET_LABELS', response.data.data);
        });
    },

    async removeLabel(_, labelId) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/nomenclature/labels/${labelId}/delete`);
    }
  },

  getters: {},

  namespaced: true,
};
