export default {
  state: {
    contacts: {
      emails: [],
      phones: []
    },
  },

  mutations: {
    SET_CONTACTS(state, contacts) {
      state.contacts.emails = contacts.find(item => item.key === 'contact_email_addresses').value;
      state.contacts.phones = contacts.find(item => item.key === 'contact_call_center_numbers').value;
    },
  },

  actions: {
    async addContact(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/settings/contacts/store', formData);
    },

    async getContacts({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/settings/contacts')
        .then(response => {
          commit('SET_CONTACTS', response.data);
        });
    },

    async removeContact(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/settings/contacts/delete', formData);
    }
  },

  getters: {},

  namespaced: true,
};
