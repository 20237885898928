export default {
  state: {
    ratings: [],
    sortParams: {
      date_from: null,
      date_to: null,
      page: 1,
    },
  },

  mutations: {
    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_DATE_FROM_PARAM(state, dateFrom) {
      state.sortParams.date_from = dateFrom;
    },

    SET_DATE_TO_PARAM(state, dateTo) {
      state.sortParams.date_to = dateTo;
    },

    SET_RATINGS(state, ratings) {
      state.ratings = ratings;
    },
  },

  actions: {
    async exportRatings({ state }) {
      let url = '/panel/ratings?export';

      for (let key in state.sortParams) {
        if (key !== 'page' && state.sortParams[key]) {
          url += `&${key}=${state.sortParams[key]}`;
        }
      }

      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(url);

        return response.data.url;
      } catch (error) {
        throw error;
      }
    },

    async getRatings({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_DATE_FROM_PARAM', null);
        commit('SET_DATE_TO_PARAM', null);
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.dateFrom !== undefined) {
          commit('SET_DATE_FROM_PARAM', payload.dateFrom);
        }

        if (payload.dateTo !== undefined) {
          commit('SET_DATE_TO_PARAM', payload.dateTo);
        }
      }

      let url = '/panel/ratings';

      for (let key in state.sortParams) {
        if (state.sortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
        }
      }

      // eslint-disable-next-line no-undef
      await axios.get(url)
        .then((response) => {
          commit('SET_RATINGS', response.data);
        });
    },
  },

  getters: {},

  namespaced: true,
};
