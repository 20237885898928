export default {
  state: {
    admins: [],
    roles: {
      1: 'Суперадминистратор',
      2: 'Администратор',
      3: 'Менеджер доставки',
      4: 'Оператор доставки',
      5: 'Маркетолог',
      6: 'Менеджер контента',
      7: 'Гость'
    },
    sortParams: {
      page: 1,
      phrase: '',
    },
  },

  mutations: {
    SET_ADMINS(state, admins) {
      admins.data.forEach((el) => {
        el.roleName = state.roles[el.roles[0].id];
      });

      state.admins = admins;
    },

    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_SEARCH_PHRASE_PARAM(state, phrase) {
      state.sortParams.phrase = phrase;
    },
  },

  actions: {
    async addAdmin(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/admins/create', formData);
    },

    async editAdmin(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/admins/edit', formData);
    },

    async getAdminById(_, adminId) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(`/panel/admins/get?admin_id=${adminId}`);
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    async getAdmins({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_SEARCH_PHRASE_PARAM', '');
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.searchPhrase !== undefined) {
          commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
        }
      }

      let url = '/panel/admins';

      for (let key in state.sortParams) {
        if (state.sortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
        }
      }

      // eslint-disable-next-line no-undef
      await axios.get(url)
        .then((response) => {
          commit('SET_ADMINS', response.data);
        });
    },

    async removeAdmin(_, adminId) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/admins/delete?id=${adminId}`);
    }
  },

  getters: {},

  namespaced: true,
};
