export default {
  state: {
    promos: [],
    sortParams: {
      page: 1,
      per_page: 10,
      phrase: '',
      sort_column: 'id',
      sort_dir: 'asc',
    },
  },

  mutations: {
    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_PER_PAGE_PARAM(state, perPage) {
      state.sortParams.per_page = perPage;
    },

    SET_PROMOS(state, promos) {
      promos.data.forEach((el) => {
        if (el.promocodes) {
          el.promocodes = el.promocodes.map(item => item.id);
        }
      });

      state.promos = promos;
    },

    SET_SEARCH_PHRASE_PARAM(state, phrase) {
      state.sortParams.phrase = phrase;
    },

    SET_SORT_COLUMN_PARAM(state, sortColumn) {
      state.sortParams.sort_column = sortColumn;
    },

    SET_SORT_DIRECTION_PARAM(state, sortDirection) {
      state.sortParams.sort_dir = sortDirection;
    },
  },

  actions: {
    async addPromo(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/promos/store', formData);
    },

    async editPromo(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/promos/update', formData);
    },

    async getPromoById(_, promoId) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(`/panel/promos/${promoId}`);

        return response.data;
      } catch (error) {
        throw error;
      }
    },

    async getPromos({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_PER_PAGE_PARAM', 10);
        commit('SET_SEARCH_PHRASE_PARAM', '');
        commit('SET_SORT_COLUMN_PARAM', 'id');
        commit('SET_SORT_DIRECTION_PARAM', 'asc');
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.perPage !== undefined) {
          commit('SET_PER_PAGE_PARAM', payload.perPage);
        }

        if (payload.searchPhrase !== undefined) {
          commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
        }

        if (payload.sortColumn !== undefined) {
          commit('SET_SORT_COLUMN_PARAM', payload.sortColumn);
        }

        if (payload.sortDirection !== undefined) {
          commit('SET_SORT_DIRECTION_PARAM', payload.sortDirection);
        }
      }

      let url = '/panel/promos';

      for (let key in state.sortParams) {
        if (state.sortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
        }
      }

      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(url);

        if (payload?.returnData) {
          return response.data;
        } else {
          commit('SET_PROMOS', response.data);
        }
      } catch (error) {
        throw error;
      }
    },

    async removePromo(_, promoId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/promos/delete', {
        id: promoId
      });
    }
  },

  getters: {},

  namespaced: true,
};
