import Vue from 'vue';
import store from '@/store';

Vue.use({
  install(Vue) {
    Vue.prototype.$toasts = {
      add(item, timeout = 5000) {
        item.delay = timeout;

        if (typeof item.message !== 'string') {
          item.message = item.message.response?.data?.message || 'Что-то пошло не так';
        }

        store.commit('toasts/ADD_TOAST', item);
      }
    };
  }
});