export default {
  state: {
    tagIcons: [],
    tags: [],
  },

  mutations: {
    SET_TAG_ICONS(state, tagIcons) {
      state.tagIcons = tagIcons;
    },

    SET_TAGS(state, tags) {
      state.tags = tags;
    },
  },

  actions: {
    async addIcon(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/organizations/tags/icons/store', formData);
    },

    async addTag(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/organizations/tags/store', formData);
    },

    async editIcon(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/organizations/tags/icons/update', formData);
    },

    async editTag(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/organizations/tags/update', formData);
    },

    async getTagIcons({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/organizations/tags/icons')
        .then((response) => {
          commit('SET_TAG_ICONS', response.data);
        });
    },

    async getTags({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/organizations/tags')
        .then(response => {
          commit('SET_TAGS', response.data);
        });
    },

    async removeIcon(_, iconId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/organizations/tags/icons/delete', {
        id: iconId
      });
    },

    async removeTag(_, tagId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/organizations/tags/delete', {
        id: tagId
      });
    },

    async syncTags(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/organizations/sync-tags', formData);
    },
  },

  getters: {},

  namespaced: true,
};
