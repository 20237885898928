export default {
  state: {
    blog: [],
    sortParams: {
      page: 1,
      phrase: '',
    },
  },

  mutations: {
    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_BLOG(state, blog) {
      blog.data = blog.data.map((item) => ({
        ...item,
        status: !!item.status
      }));

      state.blog = blog;
    },

    SET_SEARCH_PHRASE_PARAM(state, phrase) {
      state.sortParams.phrase = phrase;
    },
  },

  actions: {
    async addArticle(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/blog/store', formData);
    },

    async editArticle(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/blog/update', formData);
    },

    async getBlog({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_SEARCH_PHRASE_PARAM', '');
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.searchPhrase !== undefined) {
          commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
        }
      }

      let url = '/panel/blog';

      for (let key in state.sortParams) {
        if (state.sortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
        }
      }

      // eslint-disable-next-line no-undef
      await axios.get(url)
        .then((response) => {
          commit('SET_BLOG', response.data);
        });
    },

    async removeArticle(_, itemId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/blog/delete', {
        id: itemId
      });
    }
  },

  getters: {},

  namespaced: true,
};
